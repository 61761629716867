<script>
import AlarmEventHandle from "@/views/collision-alarm/alarm-event/AlarmEventHandle";
import AlarmEventDetail from "@/views/collision-alarm/alarm-event/AlarmEventDetail";
import { enumHandleStatus, getLabelByValue } from "@/utils/EnumUtils";
// import CommonApi from '@/api/CommonApi'
// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
import DataTransformUtils from "@/utils/DataTransformUtils";
import moment from "moment";
import { Debounce } from "@/utils/OperateUtils";
export default {
  name: "AlarmEvent",
  components: {
    AlarmEventHandle,
    AlarmEventDetail,
  },
  data() {
    return {
      tableHeight: 200,
      organStructure: [],
      enumAlarmType: [],
      enumAlarmGrade: [],
      enumStateGrade: [],
      enumHandleStatus: enumHandleStatus,
      getLabelByValue: getLabelByValue,
      formSearch: {
        warnName: "",
        warnType: "",
        warnLevel: "",
        currSituation: "",
        orgId: [],
        time: ["", ""],
        warnStatus: "",
      },
      searchData: {
        warnName: "",
        warnType: "",
        warnLevel: "",
        currSituation: "",
        orgId: "",
        startDate: "",
        endDate: "",
        warnStatus: "",
        order: "",
        current: 1,
        size: 10,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
      handleVisible: false,
      detailVisible: false,
      selectRowId: "",
    };
  },
  mounted() {
    this.searchData.warnName = this.formSearch.warnName =
      this.$route.params.warnName || "";

    this.searchData.warnType = this.formSearch.warnType =
      this.$route.params.warnType || "";

    this.searchData.warnLevel = this.formSearch.warnLevel =
      this.$route.params.warnLevel || "";

    this.searchData.currSituation = this.formSearch.currSituation =
      this.$route.params.currSituation || "";

    if (this.$route.params.orgId) {
      let orgIdArr = this.$route.params.orgId.split(",");
      this.formSearch.orgId = orgIdArr.slice(this.platformType);
      this.searchData.orgId = orgIdArr[orgIdArr.length - 1];
    } else {
      // this.formSearch.orgId = [this.platformId];
      // this.searchData.orgId = this.platformId;
    }

    this.searchData.warnStatus = this.formSearch.warnStatus =
      this.$route.params.warnStatus || "";

    this.getAlarmTypeList();
    this.getAlarmGradeList();
    this.getStateGradeList();
    // this.getOrganStructure();
    this.getAlarmEventList();
    this.tableHeight = this.$refs.tableContainer.clientHeight - 98;
    window.addEventListener("resize", this.tableResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.tableResize);
  },
  computed: {
    platformType() {
      return this.$store.state.platform.type;
    },
    platformId() {
      return this.$store.state.platform.id;
    },
    //获取路由里的id
    id() {
      return this.$route.query.id;
    }
  },
  methods: {
    getAlarmTypeList() {
      this.$api.getDictList("WARN_TYPE").then((response) => {
        if (response.code == 200) {
          this.enumAlarmType = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getAlarmGradeList() {
      this.$api.getDictList("WARN_LEVEL").then((response) => {
        if (response.code == 200) {
          this.enumAlarmGrade = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getStateGradeList() {
      this.$api.getDictList("WARN_SITUATION").then((response) => {
        if (response.code == 200) {
          this.enumStateGrade = response.data.sort((a, b) => a.sort - b.sort);
        }
      });
    },
    getOrganStructure() {
      this.$api.getOrganStructure().then((response) => {
        if (response.code == 200) {
          let arr = response.data.map((item) => ({
            value: item.id,
            label: item.orgName,
            parent: item.parentId || "",
            parents: item.parentIds || "",
            type: item.type,
          }));
          console.log(arr);

          let treeArr = DataTransformUtils.toTree(
            arr,
            "value",
            "parent",
            "children"
          );
          this.organStructure = treeArr;
          // 只显示平台组织架构自身及以下数据
          //   let stark = [];
          //   stark = stark.concat(treeArr);
          //   while (stark.length) {
          //     let temp = stark.shift();
          //     if (temp.children) {
          //       stark = stark.concat(temp.children);
          //     }
          //     if (temp.value == this.platformId) {
          //       this.organStructure = [temp];
          //     }
          //   }
        }
      });
    },
    organFilter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    getAlarmEventList() {
      this.tableLoading = true;
      let params = { ...this.searchData }
      if (this.id) params.id = this.id;
      this.$api
        .getAlarmEventList(params)
        .then((response) => {
          if (response.code == 200) {
            this.tableData = response.data.records
              ? response.data.records.map((item, index) => {
                item.key =
                  (this.searchData.current - 1) * this.searchData.size +
                  index +
                  1;
                item.alarmDate = item.startTime
                  ? moment(item.startTime).format("YYYY-MM-DD")
                  : "";
                item.alarmTime = item.startTime
                  ? moment(item.startTime).format("HH:mm:ss")
                  : "";
                if (item.startTime && item.endTime) {
                  item.alarmDuration = moment(item.endTime).diff(
                    moment(item.startTime),
                    "hours"
                  );
                } else {
                  item.alarmDuration = 0;
                }
                return item;
              })
              : [];
            this.total = response.data.total;
          } else {
            response.msg && this.$message.error(response.msg);
          }
          this.tableLoading = false;
        })
        .catch((err) => {
          err.message && this.$message.error(err.message);
          this.tableLoading = false;
        });
    },
    pageNumChange(current) {
      this.searchData.current = current;
      this.getAlarmEventList();
    },
    pageSizeChange(current, size) {
      this.searchData.current = 1;
      this.searchData.size = size;
      this.getAlarmEventList();
    },
    btnQuery: Debounce(function () {
      this.searchData.warnName = this.formSearch.warnName;
      this.searchData.warnType = this.formSearch.warnType;
      this.searchData.warnLevel = this.formSearch.warnLevel;
      this.searchData.currSituation = this.formSearch.currSituation;
      this.searchData.orgId = this.formSearch.orgId.length
        ? this.formSearch.orgId[this.formSearch.orgId.length - 1]
        : "";
      this.searchData.startDate = this.formSearch.time[0]
        ? moment(this.formSearch.time[0]).format("YYYY-MM-DD") + " 00:00:00"
        : "";
      this.searchData.endDate = this.formSearch.time[1]
        ? moment(this.formSearch.time[1]).format("YYYY-MM-DD") + " 23:59:59"
        : "";
      this.searchData.warnStatus = this.formSearch.warnStatus;
      this.searchData.order = "";
      this.searchData.current = 1;
      this.getAlarmEventList();
    }),
    btnReset: Debounce(function () {
      this.searchData.warnName = this.formSearch.warnName = "";
      this.searchData.warnType = this.formSearch.warnType = "";
      this.searchData.warnLevel = this.formSearch.warnLevel = "";
      this.searchData.currSituation = this.formSearch.currSituation = "";
      // this.formSearch.orgId = [this.platformId];
      // this.searchData.orgId = this.platformId;
      this.searchData.startDate = this.searchData.endDate = "";
      this.formSearch.time = ["", ""];
      this.searchData.warnStatus = this.formSearch.warnStatus = "";
      this.searchData.order = "";
      this.searchData.current = 1;
      this.getAlarmEventList();
    }),
    handleModal(record) {
      this.selectRowId = record.id;
      this.handleVisible = true;
    },
    handleOk() {
      this.handleVisible = false;
      this.getAlarmEventList();
    },
    handleCancel() {
      this.handleVisible = false;
    },
    detailModal(record) {
      this.selectRowId = record.id;
      this.detailVisible = true;
    },
    detailClose() {
      this.detailVisible = false;
    },
    tableChange(pagination, filters, sorter) {
      if (sorter.order == "ascend") {
        this.searchData.order = 0;
      } else if (sorter.order == "descend") {
        this.searchData.order = 1;
      } else {
        this.searchData.order = "";
      }
      this.searchData.current = 1;
      this.getAlarmEventList();
    },
    tableResize() {
      this.tableHeight = this.$refs.tableContainer.clientHeight - 98;
    },
  },
};
</script>

<template>
  <div class="rate-manage-container search-container">
    <div class="container-toolbar" @keyup.enter="btnQuery">
      <div class="toolbar-item">
        <label class="toolbar-label">预警名称</label>
        <a-input class="toolbar-input" v-model="formSearch.warnName" allowClear />
      </div>
      <div class="toolbar-item">
        <label class="toolbar-label">预警类型</label>
        <a-select class="toolbar-select" placeholder="全部" v-model="formSearch.warnType" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value>全部</a-select-option>
          <a-select-option
            v-for="item in enumAlarmType"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="toolbar-item">
        <label class="toolbar-label">预警等级</label>
        <a-select class="toolbar-select" placeholder="全部" v-model="formSearch.warnLevel" allowClear>
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value>全部</a-select-option>
          <a-select-option
            v-for="item in enumAlarmGrade"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <!-- <div class="toolbar-item">
        <label class="toolbar-label">事态等级</label>
        <a-select
          class="toolbar-select"
          placeholder="全部"
          v-model="formSearch.currSituation"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            v-for="item in enumStateGrade"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>-->
      <!-- <div class="toolbar-item">
        <label class="toolbar-label">组织架构</label>
        <a-cascader
          placeholder="全部"
          :allowClear="false"
          :options="organStructure"
          :show-search="{ organFilter }"
          :change-on-select="true"
          :displayRender="({ labels }) => labels[labels.length - 1]"
          v-model="formSearch.orgId"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
        </a-cascader>
      </div> -->
      <div class="toolbar-item">
        <label class="toolbar-label">预警时间</label>
        <a-range-picker class="toolbar-datepicker" v-model="formSearch.time" />
      </div>
      <div class="toolbar-item">
        <label class="toolbar-label">处置状态</label>
        <a-select
          class="toolbar-select"
          placeholder="全部"
          v-model="formSearch.warnStatus"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value>全部</a-select-option>
          <a-select-option
            v-for="item in enumHandleStatus"
            :value="item.value"
            :key="item.value"
          >{{ item.label }}</a-select-option>
        </a-select>
      </div>
      <div class="toolbar-item">
        <a-button type="primary" @click="btnQuery" class="m-r-8">
          <a-icon type="search" />查询
        </a-button>
        <a-button @click="btnReset" class="m-r-8">
          <a-icon type="reload" />重置
        </a-button>
      </div>
    </div>
    <div class="container-content" ref="tableContainer">
      <a-table
        class="alarm-table"
        :locale="{ emptyText: this.tableLoading ? '加载中...' : '暂无数据' }"
        :scroll="{ y: tableHeight }"
        :columns="[
          {
            title: '序号',
            dataIndex: 'key',
            width: 90,
          },
          {
            title: '预警名称',
            dataIndex: 'warnName',
            width: 180,
            ellipsis: true,
          },
          {
            title: '事件描述',
            dataIndex: 'warnDesc',
          },
          {
            title: '预警类型',
            dataIndex: 'typeName',
            align: 'center',
            width: 120,
            ellipsis: true,
          },
          {
            title: '预警等级',
            dataIndex: 'levelName',
            align: 'center',
            width: 120,
            ellipsis: true,
          },
          {
            title: '组织架构',
            dataIndex: 'orgName',
            align: 'center',
            width: 180,
            ellipsis: true,
          },
          {
            title: '预警时间',
            dataIndex: 'alarmDateTime',
            align: 'center',
            width: 120,
            ellipsis: true,
            scopedSlots: { customRender: 'alarmDateTime' },
          },
          {
            title: '预警时长',
            dataIndex: 'alarmDuration',
            align: 'center',
            width: 120,
            ellipsis: true,
            scopedSlots: { customRender: 'alarmDuration' },
            sorter: true,
            // sorter: (a, b) => a.alarmDuration - b.alarmDuration
          },
          {
            title: '处置状态',
            dataIndex: 'warnStatus',
            align: 'center',
            width: 120,
            ellipsis: true,
            scopedSlots: { customRender: 'warnStatus' },
          },
          {
            title: '操作',
            dataIndex: 'operation',
            align: 'center',
            width: 90,
            scopedSlots: { customRender: 'operation' },
          },
        ]"
        :data-source="tableData"
        :pagination="false"
        @change="tableChange"
      >
        <label
          slot="situationName"
          slot-scope="text"
          :class="{
            'grade-blue': text == '蓝色',
            'grade-orange': text == '橙色',
            'grade-yellow': text == '黄色',
            'grade-red': text == '红色',
          }"
        >{{ text }}</label>
        <label slot="alarmDateTime" slot-scope="text, record">
          <span>{{ record.alarmDate }}</span>
          <br />
          <span>{{ record.alarmTime }}</span>
        </label>
        <template slot="alarmDuration" slot-scope="text">
          <span v-if="text == 0">1小时内</span>
          <span v-else-if="text >= 1 && text < 24">{{ text }}小时</span>
          <span v-else>{{ Math.floor(text / 24) }}天</span>
        </template>
        <label slot="warnStatus" slot-scope="text">{{ getLabelByValue(enumHandleStatus, text) }}</label>
        <a
          slot="operation"
          slot-scope="text, record"
          href="javascript:void(0)"
          @click="
            () => {
              if (record.warnStatus == 0) {
                handleModal(record);
              } else {
                detailModal(record);
              }
            }
          "
        >{{ record.warnStatus == 0 ? "处理" : "查看" }}</a>
      </a-table>
    </div>
    <div class="content-page pagination-container" v-if="total">
      <a-pagination
        :showQuickJumper="true"
        :showSizeChanger="true"
        :current="searchData.current"
        :pageSize="searchData.size"
        :total="total"
        :show-total="(total) => `共${total}条`"
        @change="pageNumChange"
        @showSizeChange="pageSizeChange"
      />
    </div>
    <AlarmEventHandle
      :visible="handleVisible"
      :detailId="selectRowId"
      @ok="handleOk"
      @cancel="handleCancel"
    ></AlarmEventHandle>
    <AlarmEventDetail :visible="detailVisible" :detailId="selectRowId" @close="detailClose"></AlarmEventDetail>
  </div>
</template>

<style lang="scss" scoped>
.alarm-table {
  /deep/ {
    .ant-table-tbody > tr > td {
      height: 96px;
    }
  }
}
</style>
